var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function Pagination(theme) {
    var isLight = theme.palette.mode === 'light';
    var rootStyle = function (ownerState) {
        var outlinedVariant = ownerState.variant === 'outlined';
        var softVariant = ownerState.variant === 'soft';
        var defaultStyle = {
            '& .MuiPaginationItem-root': __assign(__assign({}, (outlinedVariant && {
                borderColor: alpha(theme.palette.grey[500], 0.32),
            })), { '&.Mui-selected': {
                    fontWeight: theme.typography.fontWeightMedium,
                } }),
        };
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.color === color && __assign({}, (softVariant && {
            '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                    color: theme.palette[color][isLight ? 'dark' : 'light'],
                    backgroundColor: alpha(theme.palette[color].main, 0.16),
                    '&:hover': {
                        backgroundColor: alpha(theme.palette[color].main, 0.32),
                    },
                },
            },
        }))))); });
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle], false);
    };
    return {
        MuiPagination: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
